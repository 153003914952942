import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function CounterBigCurveSvg() {
  const svgRef = useRef(null);
  const pathRef = useRef(null);

  useEffect(() => {
    const pathElement = pathRef.current;

    if (pathElement) {
      const pathLength = pathElement.getTotalLength(); // Get the actual length of the path

      gsap.set(pathElement, { 
        opacity: 0, 
        strokeDasharray: pathLength, 
        strokeDashoffset: pathLength 
      });

      gsap.timeline({
        scrollTrigger: {
          trigger: svgRef.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
        },
      })
      .to(pathElement, {
        opacity: 1,
        strokeDashoffset: 0,
        duration: 1.5, // Increase if needed
        delay: 0.5,
        ease: "power1.inOut",
      });
    }
  }, []);

  return (
    <svg
      ref={svgRef}
      width="181"
      height="162"
      viewBox="0 0 181 162"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="w-[100px] md:w-[100px] lg:w-[200px] h-auto Counter-Title-Svg"
    >
      <path
        ref={pathRef}
        d="M5.21685 84.9677C-1.50919 127.595 -4.07473 197.702 39.4669 137.096C93.894 61.338 81.0177 -3.12823 56.7537 15.1464C32.4909 33.4199 11.4961 188.829 67.4299 143.666C123.365 98.5027 175.968 -17.3607 131.598 4.05719C87.2268 25.4751 66.767 157.865 108.628 132.687C150.49 107.511 187.83 37.7804 178.086 7.16671"
        stroke="#FB2979"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
