import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function HalfShortArrow() {
  const svgRef = useRef(null);
  const pathRef = useRef(null);

  useEffect(() => {
    const pathElement = pathRef.current;

    if (pathElement) {
      const pathLength = pathElement.getTotalLength(); // Get actual path length

      gsap.set(pathElement, { 
        opacity: 0, 
        strokeDasharray: pathLength, 
        strokeDashoffset: pathLength 
      });

      gsap.timeline({
        scrollTrigger: {
          trigger: svgRef.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
        },
      })
      .to(pathElement, {
        opacity: 1,
        strokeDashoffset: 0,
        duration: 1.5,
        ease: "power1.inOut",
      });
    }
  }, []);

  return (
    <svg
      ref={svgRef}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="md:!block hidden !m-[0] !w-[20px] lg:!w-[30px]"
    >
      <path
        ref={pathRef}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.625 26.5438C27.1721 26.4978 27.5777 26.0117 27.5311 25.4581C27.4845 24.9045 27.0032 24.4929 26.4562 24.5389L14.1796 25.5706C14.3567 26.2107 14.4718 26.8765 14.518 27.5612L26.625 26.5438ZM10.5585 20.2565L25.8567 1.65025C26.2074 1.22364 26.1494 0.589338 25.727 0.233483C25.3047 -0.122372 24.6779 -0.0650194 24.3272 0.361584L8.8312 19.2085C9.44389 19.4999 10.0222 19.8518 10.5585 20.2565ZM3.40509 18.3078L1.98329 3.91943C1.92865 3.36653 1.44151 2.96215 0.89522 3.01623C0.348935 3.07031 -0.0496273 3.56237 0.00500916 4.11527L1.44929 18.7311C2.07633 18.5274 2.73054 18.384 3.40509 18.3078Z"
        fill="none"
        stroke="#FB2979"
        strokeWidth="1.9"
      />
    </svg>
  );
}
